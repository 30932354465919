import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Main from '~components/layouts/Main'
import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'
import HeroSection from '~components/layouts/marketing/HeroSection'
import SEO from '~components/seo'

const StyledBtn = styled(Link)`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: auto !important;
  }
`

const heroBanner = 'https://leatherspa.imgix.net/Customer-Services/Individual-Pages/Pick-up-Service_Image.jpg'

const PickUpServicePage = () => (
  <Layout>
    <SEO
      title="Pickup Service"
      pathname="/customer-services/pick-up"
      description="We offer two types of pick-up services throughout Manhattan, Brooklyn and Queens"
    />
    <Main className="mb-5">
      <HeroSection title={'Pickup Service'} fluid={false}
        breadcrumb={{ title: 'At Your Service', path: '/customer-services' }}
        mobileImg={heroBanner}
        desktopImg={heroBanner}
      >
        <>
          <h2>We offer two types of pick-up services throughout Manhattan, Brooklyn and Queens:</h2>
          <p>
            The cost is $30-$45 for the pickup and return delivery service and must be pre-paid prior to pick-up.
          </p>
          <p>How it works:</p>
          <ul>
            <li>
              <strong>Standard Pick-up:</strong> We will come pick up your items the following business day with no set time frame between 10 am to 7 pm.
            </li>
            <li>
              <strong>On-Demand Pick-up:</strong> We will come pick up your items the same day, within the two-hour window you select.
            </li>
          </ul>
          <p>
            <em>
              This service is limited to shoes, handbags, garments, and small leather goods (belts/wallets/etc); 10 items or less. For larger orders or items/pieces (furniture) please contact us <a href="mailto:repairs@leatherspa.com">repairs@leatherspa.com</a> to schedule pick-up arrangements.
            </em>
          </p>
          <StyledBtn className="btn btn-primary px-lg-5 mt-4" to="/estimates">
            Schedule A Pick-Up
          </StyledBtn>
        </>
      </HeroSection>
    </Main>
  </Layout>
)

export default PickUpServicePage
